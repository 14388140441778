<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Upload your company logo
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 550 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <div class="row align-items-center" v-if="loading == false">
            <div class="col-12 col-sm-12 col-md-6 col-lg-8">
              <p>
                For proper logo alignment in reports, please make sure that the
                image doesn't contain whitespace around actual logo
              </p>
              <div
                class="drop-upload"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <input
                  type="file"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                />
                <p>Drop files here to upload</p>
                <ul class="file-list mt-4" v-if="this.filelist.length" v-cloak>
                  <li
                    class="text-sm p-1"
                    v-for="(file, key) in filelist"
                    :key="key"
                  >
                    {{ file.name
                    }}<button
                      type="button"
                      @click="remove(filelist.indexOf(file))"
                      title="Remove file"
                    >
                      <i class="fal fa-times color-blue"></i>
                    </button>
                  </li>
                </ul>
              </div>
              <button
                class="button-expertel mr-3"
                @click.prevent="uploadLogo()"
              >
                Upload
              </button>
              <button class="btn btn-outline-expertel rounded-0">
                Clear
              </button>
            </div>
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-4 mt-4 mt-md-0 text-center text-md-left"
            >
              <h2 class="d-block text-center text-md-left">Current logo</h2>
              <img
                :src="logos.main_logo"
                alt="Expertel IQ"
                class="logo"
                style="max-width: 160px;"
                v-if="logos.main_logo !== false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      logos: [],
      filelist: [],
      files_uploads: [],
      loading: true
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    onChange(event) {
      this.filelist = [...this.$refs.file.files];
      this.files_uploads = event.dataTransfer.files[0];
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(event); // Trigger the onChange event manually
    },
    getData() {
      const self = this;
      this.loading = true;

      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/logo`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.logos = response.data;
          self.calculateHeight();
          self.loading = false;
        })
        .catch(error => self.makeToast("ERROR", error, "danger"));
    },
    uploadLogo() {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      formData.append("file", this.files_uploads);

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/logo`,
        responseType: "json",
        data: formData,
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.makeToast(
            "SUCCESS",
            "Notification settings changed successfully",
            "success"
          );
          self.logos = response.data;
          self.loading = false;
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    }
  }
};
</script>
